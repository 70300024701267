.main-layout {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  width: 100%;

  &-navbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100;
  }

  &-main {
    margin-top: 64px;
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
  }
}
