.main-navbar {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  height: 64px;
  padding: 0 24px;
  color: #fff;
  background-color: #121212;
  background-image: linear-gradient(rgba(255, 255, 255, 0.09), rgba(255, 255, 255, 0.09));
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14),
    0px 1px 10px 0px rgba(0, 0, 0, 0.12);

  &-logo {
    display: flex;
    align-items: center;
  }
}
